import React from 'react'
import ReactDOM from 'react-dom'

import { BaseClass } from '.'

export const vConsoleWebviewPlugin = (
  sdkInstance: BaseClass,
  DebugPanel: React.ElementType<{ sdkInstance: BaseClass }>,
  vConsole: any
) => {
  const PluginClass = vConsole.VConsolePlugin
  const plugin = new PluginClass('driver-webview-bridge-js', 'Webview SDK')

  plugin.on('renderTab', (callback: any) => {
    callback("<div id='webview-sdk' style='width:100%;height:100%'/>")
  })

  plugin.on('ready', () => {
    const target = document.getElementById('webview-sdk')
    if (target) {
      ReactDOM.render(<DebugPanel sdkInstance={sdkInstance} />, target)
    }
  })

  plugin.on('addTool', (callback: any) => {
    const clearLocalStorage = {
      name: 'Clear LocalStorage',
      onClick: () => {
        localStorage.clear()
      },
    }

    const reload = {
      name: 'Reload',
      onClick: () => {
        location.reload()
      },
    }

    callback([clearLocalStorage, reload])
  })

  return plugin
}
