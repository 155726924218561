import { css } from '@emotion/css'
import React from 'react'

const buttonClass = css`
  border: none;
  background-color: var(--VC-BG-0);
  color: var(--VC-FG-0);
  outline: none;
  font-size: 1em;
  padding: 0 8px;
  min-width: 56px;
  min-height: 40px;
  margin-left: 8px;
`

export const VCButton: React.FC<
  React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >
> = props => <button {...props} className={buttonClass} />
