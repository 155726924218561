export enum DriverRegNavLeftButtonType {
  CLOSE = 'close',
  BACK = 'back',
  BACK_FROM_TRAINING = 'backFromTraining',
}

export enum DriverRegNavRightButtonType {
  HELP = 'help',
}

export enum StickerNavLeftButtonType {
  BACK = 'back',
  BACK_TO_APP = 'backToApp',
  BACK_FROM_STICKER = 'backFromSticker',
}

export enum StickerNavRightButtonType {
  HELP = 'help',
}

export enum DriverKitNavLeftButtonType {
  BACK = 'back',
  BACK_TO_APP = 'backToApp',
  BACK_TO_APP_WITH_CONFIRM = 'backToAppWithConfirmation', // quit webview with confirm dialog
  BROWSER_BACK_WITH_CONFIRM = 'browserBackWithConfirmation', // confirm dialog with callback
}

export enum DriverKitNavRightButtonType {
  HELP = 'help',
}

export enum DriverChoreLeftButtonType {
  BACK = 'back',
  CLOSE = 'close',
  BACK_FROM_TRAINING = 'backFromTraining',
}

export enum DriverChoreRightButtonType {
  HELP = 'help',
}
