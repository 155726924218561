import autoBind from 'auto-bind'
import { mergeRight, reject } from 'ramda'

import { prodCheatCodeListener } from './../../utils/prodCheatCodeListener'
import { BaseActionType } from '../../constants/actionType'
import { CallbackType } from '../../constants/callbackType'
import { SDKConfig } from '../../types/sdkConfig'
import { TrackingEventInfo } from '../../types/trackingEvent'
import { shallowRemoveEmptyParam } from '../../utils/removeEmptyParam'
import { driverAppWebCall } from '../../utils/webCall'
import { attachCallback } from '../../utils/webviewCallback'
import { DebugPanel } from './DebugPanel'

export class BaseClass {
  vConsole: any = null
  private debugPanel: React.ComponentType<{ sdkInstance: any }> = DebugPanel

  config: SDKConfig = {
    showDebugInfo: false,
    suppressError: false,
  }

  private _attachedCallbacks: CallbackType[] = []

  constructor(
    config?: Partial<SDKConfig>,
    debugPanel?: React.ComponentType<{ sdkInstance: any }>
  ) {
    autoBind(this)
    this.config = mergeRight(this.config, config || {})
    if (debugPanel) {
      this.debugPanel = debugPanel
    }
  }

  driverAppWebCall(params: Parameters<typeof driverAppWebCall>[0]) {
    return driverAppWebCall(params, this.config)
  }

  attachCallback(
    params: Omit<Parameters<typeof attachCallback>[0], keyof SDKConfig>
  ) {
    this._attachedCallbacks = [...this._attachedCallbacks, params.name]
    return attachCallback({
      ...params,
      ...this.config,
      onDestroy: () => {
        this._attachedCallbacks = reject(
          item => item === params.name,
          this._attachedCallbacks
        )

        if (params.onDestroy) {
          params.onDestroy()
        }
      },
    })
  }

  get attachedCallback() {
    return this._attachedCallbacks
  }

  setNavigationBarButton({
    left,
    right,
    rightText,
  }: {
    left?: string
    right?: string
    rightText?: string
  }) {
    const params = shallowRemoveEmptyParam({
      action: BaseActionType.UPDATE_NAVIGATION_BAR,
      leftItemType: left,
      rightItemType: right,
      rightItemText: rightText,
    })

    return this.driverAppWebCall(params)
  }

  injectDevTools({
    useCheatCode = true,
    isAutoInject = false,
  }: {
    useCheatCode?: boolean
    isAutoInject?: boolean
  }) {
    if (this.vConsole) this.removeDevTools()

    if (this.config.showDebugInfo) {
      console.log('Injected dev tools')
    }

    if (isAutoInject) {
      this.injectVConsole()
    }

    if (useCheatCode) {
      prodCheatCodeListener({
        ...this.config,
        onSuccess: () => !this.vConsole && this.injectVConsole(),
      })
    } else if (!this.vConsole) {
      this.injectVConsole()
    }
  }

  private async injectVConsole() {
    const VConsole = (await import('vconsole')).default
    const vConsoleInstance = new VConsole()
    const vConsoleWebviewPlugin = (await import('./vConsoleWebviewPlugin'))
      .vConsoleWebviewPlugin

    vConsoleInstance.addPlugin(
      vConsoleWebviewPlugin(this, this.debugPanel, VConsole)
    )

    // eslint-disable-next-line fp/no-mutation
    this.vConsole = vConsoleInstance
  }

  removeDevTools() {
    if (this.vConsole) {
      this.vConsole?.destroy()
    }
  }

  sensorTrack(eventInfo: TrackingEventInfo) {
    return this.driverAppWebCall({
      action: BaseActionType.SENSOR_TRACK_H5,
      ...eventInfo,
    })
  }
}
