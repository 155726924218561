import { css } from '@emotion/css'
import React, { useCallback, useState } from 'react'

import { VCButton } from './input/VCButton'
import { VCInput } from './input/VCInput'

const wrapperClass = css`
  border-bottom: 1px solid var(--VC-FG-3);
  display: flex;
  line-height: 3em;
`

const inputClass = css`
  width: 100%;
`

const inputWrapperClass = css`
  width: 100%;
`

export const AddressBar: React.FC = () => {
  const [url, setUrl] = useState<string>(window.location.href)

  const handleSubmit = useCallback(() => {
    window.location.href = url
  }, [url])

  return (
    <div className={wrapperClass}>
      <div className={inputWrapperClass}>
        <VCInput
          value={url}
          onChange={e => {
            setUrl(e.target.value)
          }}
          placeholder="URL"
          className={inputClass}
        />
      </div>
      <VCButton
        type="button"
        onClick={() => {
          handleSubmit()
        }}
      >
        Go
      </VCButton>
    </div>
  )
}
