import autoBind from 'auto-bind'

import { DriverKitActionType } from '../../constants/actionType'
import { CallbackType } from '../../constants/callbackType'
import {
  DriverKitNavLeftButtonType,
  DriverKitNavRightButtonType,
} from '../../constants/navButtonType'
import { SDKConfig } from '../../types/sdkConfig'
import { shallowRemoveEmptyParam } from '../../utils/removeEmptyParam'
import { BaseClass } from '../BaseClass'
import { DebugPanel } from './DebugPanel'

export class DriverKit extends BaseClass {
  constructor(config?: Partial<SDKConfig>) {
    super(config, DebugPanel)
    autoBind(this)
  }

  setNavigationBarButton(
    {
      left,
      right,
    }: {
      left?: DriverKitNavLeftButtonType
      right?: DriverKitNavRightButtonType
    },
    backConfirm?: {
      message: string
      confirmBtnLabel: string
      cancelBtnLabel: string
      trackingOnConfirmation?: {
        eventName: string
        screen: string
        anyAdditionalInfo: string
      }
      onConfirm: () => void
    }
  ) {
    const baseParam = {
      action: DriverKitActionType.UPDATE_NAVIGATION_BAR,
      leftItemType: left,
      rightItemType: right,
    }

    if (backConfirm) {
      const { onConfirm, ...rest } = backConfirm

      const callbackFnName = CallbackType.BACK_CONFIRM
      this.attachCallback({ name: callbackFnName, fn: onConfirm })

      return this.driverAppWebCall(
        shallowRemoveEmptyParam({
          ...rest,
          ...baseParam,
          callbackOnConfirm: callbackFnName,
        })
      )
    }

    return this.driverAppWebCall(shallowRemoveEmptyParam(baseParam))
  }

  setHelpPageLink = (url: string) =>
    this.driverAppWebCall({
      action: DriverKitActionType.UPDATE_HELP_LINK,
      url,
    })

  setTitle(title: string) {
    return this.driverAppWebCall({
      action: DriverKitActionType.SET_TITLE,
      title,
    })
  }

  openWebview(url: string) {
    return this.driverAppWebCall({
      action: DriverKitActionType.OPEN_WEBVIEW,
      url,
    })
  }
}
