import { CornerType } from './../constants/cornerType'
import { SDKConfig } from './../types/sdkConfig'

export type OnClickCallback = (param: { x: number; y: number }) => void

type ClickHandler = (e: WindowEventMap['click']) => void
type TouchHandler = (e: WindowEventMap['touchstart']) => void

let clickHandler: ClickHandler | null = null
let touchHandler: TouchHandler | null = null

const getClickHandler =
  (onClick: OnClickCallback): ClickHandler =>
  e => {
    onClick({ x: e.clientX, y: e.clientY })
  }

const getTouchHandler =
  (onClick: OnClickCallback): TouchHandler =>
  e => {
    onClick({ x: e.touches[0].clientX, y: e.touches[0].clientY })
  }

/** @internal */
export const removeCornerClickTracker = () => {
  if (clickHandler) window.removeEventListener('click', clickHandler)
  if (touchHandler) window.removeEventListener('touchstart', touchHandler)
  // eslint-disable-next-line fp/no-mutation
  clickHandler = null
  // eslint-disable-next-line fp/no-mutation
  touchHandler = null
}

/** @internal */
export const addWindowClickTracker = ({
  onClick,
}: {
  onClick: OnClickCallback
}) => {
  removeCornerClickTracker()

  if ('ontouchstart' in window) {
    // eslint-disable-next-line fp/no-mutation
    touchHandler = getTouchHandler(onClick)
    window.addEventListener('touchstart', touchHandler)
  } else {
    // eslint-disable-next-line fp/no-mutation
    clickHandler = getClickHandler(onClick)
    window.addEventListener('click', clickHandler)
  }
}

/** @internal */
export const addCornerClickTracker = ({
  onCornerClick,
  cheatCodeCornerDetectionPercentage: thresholdPercentage = 20,
}: SDKConfig & {
  onCornerClick: (c: CornerType) => void
}) => {
  addWindowClickTracker({
    onClick: ({ x, y }) => {
      const xPercentage = ((window.innerWidth - x) / window.innerWidth) * 100
      const yPercentage = ((window.innerHeight - y) / window.innerHeight) * 100

      if (
        xPercentage < thresholdPercentage &&
        yPercentage < thresholdPercentage
      ) {
        onCornerClick(CornerType.BOTTOM_RIGHT)
      } else if (
        xPercentage > 100 - thresholdPercentage &&
        yPercentage < thresholdPercentage
      ) {
        onCornerClick(CornerType.BOTTOM_LEFT)
      } else if (
        xPercentage > 100 - thresholdPercentage &&
        yPercentage > 100 - thresholdPercentage
      ) {
        onCornerClick(CornerType.TOP_LEFT)
      } else if (
        xPercentage < thresholdPercentage &&
        yPercentage > 100 - thresholdPercentage
      ) {
        onCornerClick(CornerType.TOP_RIGHT)
      }
    },
  })
}
