import { EnumValues } from 'enum-values'
import { values } from 'ramda'
import React, { useCallback, useState } from 'react'

import { AddressBar } from '../../components/AddressBar'
import { VCButton } from '../../components/input/VCButton'
import { VCFormInput } from '../../components/input/VCFormInput'
import { VCSelect } from '../../components/input/VCSelect'
import { SetNavBarButtonTester } from '../../components/SetNavBarButtonTester'
import { WebMeta } from '../../components/WebMeta'
import { SnackBarType } from '../../constants'
import {
  DriverChoreLeftButtonType,
  DriverChoreRightButtonType,
} from '../../constants/navButtonType'
import { DriverChoreSDK } from '..'

type Props = {
  sdkInstance: DriverChoreSDK
}

export const DebugPanel = ({ sdkInstance }: Props) => {
  const [snackBarType, setSnackBarType] = useState<number>(0)

  const handleTakePhoto = useCallback(() => {
    sdkInstance.takePhoto(image => {
      console.log('Image received: ', image)
    })
  }, [sdkInstance])

  const handlePickImage = useCallback(() => {
    sdkInstance.pickImageFromAlbum(image => {
      console.log('Image received: ', image)
    })
  }, [sdkInstance])

  const handleOpenGalleryOrPdf = useCallback(() => {
    sdkInstance.openGalleryOrPdf(image => {
      console.log('received picture or pdf', image)
    })
  }, [sdkInstance])

  const handleSnackBar = useCallback(
    (message: string) => {
      const snackData = { type: snackBarType, msg: message }
      sdkInstance.showSnackBar(snackData)
    },
    [sdkInstance, snackBarType]
  )

  return (
    <div>
      <AddressBar />
      <WebMeta />
      <SetNavBarButtonTester
        sdkInstance={sdkInstance}
        availableLeftTypes={values(DriverChoreLeftButtonType)}
        availableRightTypes={values(DriverChoreRightButtonType)}
      />
      <VCButton type="button" onClick={handleTakePhoto}>
        Open Driver Chore Camera
      </VCButton>
      <VCButton type="button" onClick={handlePickImage}>
        Open Driver Chore Pick Image
      </VCButton>
      <VCButton type="button" onClick={handleOpenGalleryOrPdf}>
        Handle Open Gallery/Pdf
      </VCButton>
      <VCFormInput
        title="Help page link"
        onSubmit={v => {
          if (v) sdkInstance.setHelpPageLink(v)
        }}
        placeholder="URL"
      />
      <VCFormInput
        title="Close Webview"
        onSubmit={v => {
          if (v) sdkInstance.closeWebView(JSON.parse(v))
        }}
        placeholder="Toast Data (JSON)"
      />
      <VCFormInput
        title="Show Dialog"
        onSubmit={v => {
          if (v) sdkInstance.showDialog(JSON.parse(v))
        }}
        placeholder="Dialog Data (JSON)"
      />
      <>
        <VCFormInput
          title="Show Snack Bar"
          onSubmit={v => {
            if (v) handleSnackBar(v)
          }}
          placeholder="Message"
        />
        <VCSelect
          value={snackBarType}
          onChange={e => {
            setSnackBarType(parseInt(e.target.value, 10))
          }}
        >
          <option value="">-- select snackbartype --</option>
          {EnumValues.getValues(SnackBarType).map(value => (
            <option value={value} key={value}>
              {EnumValues.getNames(SnackBarType)[value as number]}
            </option>
          ))}
        </VCSelect>
      </>
    </div>
  )
}
