import { values } from 'ramda'
import React from 'react'

import { AddressBar } from '../../components/AddressBar'
import { VCFormInput } from '../../components/input/VCFormInput'
import { SetNavBarButtonTester } from '../../components/SetNavBarButtonTester'
import { WebMeta } from '../../components/WebMeta'
import {
  DriverKitNavLeftButtonType,
  DriverKitNavRightButtonType,
} from '../../constants'
import { DriverKitSDK } from '..'

type Props = {
  sdkInstance: DriverKitSDK
}

export const DebugPanel = ({ sdkInstance }: Props) => (
  <div>
    <AddressBar />
    <WebMeta />
    <SetNavBarButtonTester
      sdkInstance={sdkInstance}
      availableLeftTypes={values(DriverKitNavLeftButtonType)}
      availableRightTypes={values(DriverKitNavRightButtonType)}
    />
    <VCFormInput
      title="Title"
      onSubmit={v => {
        if (v) sdkInstance.setTitle(v)
      }}
    />
    <VCFormInput
      title="Help page link"
      onSubmit={v => {
        if (v) sdkInstance.setHelpPageLink(v)
      }}
      placeholder="URL"
    />
    <VCFormInput
      title="Open Webview"
      onSubmit={v => {
        if (v) sdkInstance.openWebview(v)
      }}
      placeholder="URL"
      buttonText="Open"
    />
  </div>
)
