import { css } from '@emotion/css'
import React from 'react'

const inputClass = css`
  padding: 0.5em;
  background-color: transparent;
  border: 1px solid var(--VC-FG-3);
  color: var(--VC-FG-0);
`

export const VCSelect: React.FC<
  React.DetailedHTMLProps<
    React.SelectHTMLAttributes<HTMLSelectElement>,
    HTMLSelectElement
  >
> = props => <select {...props} className={inputClass} />
