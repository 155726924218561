import autoBind from 'auto-bind'
import { DialogInfo } from 'src/types/showDialog'
import { SnackBarInfo } from 'src/types/snackBar'
import { toastInfo } from 'src/types/toastMessage'

import { DriverChoreActionType } from '../../constants/actionType'
import { CallbackType } from '../../constants/callbackType'
import { SDKConfig } from '../../types/sdkConfig'
import { BaseClass } from '../BaseClass'
import { DebugPanel } from './DebugPanel'

export class DriverChore extends BaseClass {
  constructor(config?: Partial<SDKConfig>) {
    super(config, DebugPanel)
    autoBind(this)
  }

  takePhoto(callback: (image: string | File) => void) {
    const callbackFnName = CallbackType.TAKE_PHOTO
    this.attachCallback({ name: callbackFnName, fn: callback })
    return this.driverAppWebCall({
      action: DriverChoreActionType.CAMERA,
      callback: callbackFnName,
    })
  }
  pickImageFromAlbum(callback: (image: string | File) => void) {
    const callbackFnName = CallbackType.PICK_IMAGE_FROM_ALBUM
    this.attachCallback({ name: callbackFnName, fn: callback })
    return this.driverAppWebCall({
      action: DriverChoreActionType.PICTURE,
      callback: callbackFnName,
    })
  }

  openGalleryOrPdf(callback: (image: string | File) => void) {
    const callbackFnName = CallbackType.OPEN_GALLERY_OR_PDF
    this.attachCallback({ name: callbackFnName, fn: callback })
    return this.driverAppWebCall({
      action: DriverChoreActionType.GALLERY_OR_PDF,
      callback: callbackFnName,
    })
  }

  closeWebView(toastMessage: toastInfo) {
    return this.driverAppWebCall({
      action: DriverChoreActionType.CLOSE_WEB_VIEW,
      ...toastMessage,
    })
  }

  showDialog(dialogInfo: DialogInfo) {
    return this.driverAppWebCall({
      action: DriverChoreActionType.SHOW_DIALOG,
      ...dialogInfo,
    })
  }

  setHelpPageLink(url: string) {
    return this.driverAppWebCall({
      action: DriverChoreActionType.UPDATE_HELP_LINK,
      url,
    })
  }

  showSnackBar(snackBarInfo: SnackBarInfo) {
    return this.driverAppWebCall({
      action: DriverChoreActionType.SHOW_SNACKBAR,
      ...snackBarInfo,
    })
  }
}
