import React from 'react'

import { AddressBar } from '../../components/AddressBar'
import { VCButton } from '../../components/input/VCButton'
import { VCFormInput } from '../../components/input/VCFormInput'
import { SetNavBarButtonTester } from '../../components/SetNavBarButtonTester'
import { WebMeta } from '../../components/WebMeta'
import { PlusSDK } from '..'

type Props = {
  sdkInstance: PlusSDK
}

export const DebugPanel = ({ sdkInstance }: Props) => (
  <div>
    <AddressBar />
    <WebMeta />
    <SetNavBarButtonTester
      sdkInstance={sdkInstance}
      availableLeftTypes={[]}
      availableRightTypes={[]}
    />
    <VCFormInput
      title="switchDeliveryMode"
      onSubmit={mode => {
        if (mode) sdkInstance.switchDeliveryMode(mode)
      }}
    />

    <VCButton type="button" onClick={() => sdkInstance.logout()}>
      Logout
    </VCButton>
  </div>
)
