import React from 'react'

import { AddressBar } from '../../components/AddressBar'
import { SetNavBarButtonTester } from '../../components/SetNavBarButtonTester'
import { WebMeta } from '../../components/WebMeta'
import { BaseClass } from '.'

type Props = {
  sdkInstance: BaseClass
}

export const DebugPanel = ({ sdkInstance }: Props) => (
  <div>
    <AddressBar />
    <WebMeta />
    <SetNavBarButtonTester
      sdkInstance={sdkInstance}
      availableLeftTypes={[]}
      availableRightTypes={[]}
    />
  </div>
)
