import autoBind from 'auto-bind'

import { PlusActionType } from '../../constants/actionType'
import { SDKConfig } from '../../types/sdkConfig'
import { BaseClass } from '../BaseClass'
import { DebugPanel } from './DebugPanel'

export type Tab = '/' | '/pickup' | '/dropoff' | '/settings'

export class Plus extends BaseClass {
  constructor(config?: Partial<SDKConfig>) {
    super(config, DebugPanel)
    autoBind(this)
  }

  switchDeliveryMode(mode: string) {
    return this.driverAppWebCall({
      action: PlusActionType.SWITCH_DELIVERY_MODE,
      mode,
    })
  }
  logout() {
    return this.driverAppWebCall({
      action: PlusActionType.LOGOUT,
    })
  }
  changeTab(tab: Tab) {
    return this.driverAppWebCall({
      action: PlusActionType.CHANGE_TAB,
      tab,
    })
  }
}
