import { css } from '@emotion/css'
import React from 'react'

import { VCButton } from './VCButton'

type Props = {
  title: string
  onSubmit: () => void
  buttonText?: string
  children?: React.ReactNode
}

const wrapperClass = css`
  border-bottom: 1px solid var(--VC-FG-3);
  display: flex;
  line-height: 3em;
`

const titleClass = css`
  flex-shrink: 0;
  padding: 0 8px;
`

const inputWrapperClass = css`
  width: 100%;
`

export const VCFormItem: React.FC<Props> = ({
  title,
  onSubmit,
  buttonText,
  children,
}) => (
  <div className={wrapperClass}>
    <div className={titleClass}>{title}</div>
    <div className={inputWrapperClass}>{children}</div>
    <VCButton
      type="button"
      onClick={() => {
        onSubmit()
      }}
    >
      {buttonText || 'Set'}
    </VCButton>
  </div>
)
