export enum BaseActionType {
  UPDATE_NAVIGATION_BAR = 'updateNavigationBar',
  SENSOR_TRACK_H5 = 'trackEventH5',
}

export enum DriverRegActionType {
  SET_SHOULD_AUTO_SHOW_REGISTRATION = 'shouldAutoShowRegistration',
  OPEN_LOGIN = 'openLogin',
  UPDATE_HELP_LINK = 'updateHelpLink',
  START_MONITOR_SMS = 'startMonitorSms',
  PICTURE = 'picture',
  CAMERA = 'camera',
  CLICK_HELP_LISTENER = 'clickHelpListener',
  GALLERY_OR_PDF = 'galleryOrPdf',
}

export enum StickerActionType {
  UPDATE_TRACKING_FOR_BACK_BUTTON = 'updateTrackingForBackButton',
  CLOSE_WEB_VIEW = 'closeWebView',
  STICKER_CAMERA = 'stickerCamera',
  CAMERA = 'camera',
}

export enum DriverKitActionType {
  UPDATE_HELP_LINK = 'updateHelpLink',
  OPEN_WEBVIEW = 'openWebview',
  UPDATE_NAVIGATION_BAR = 'updateNavigationBar',
  SET_TITLE = 'setTitle',
}

export enum DriverChoreActionType {
  CAMERA = 'camera',
  CLOSE_WEB_VIEW = 'closeWebView',
  VEHICLE_CHANGE_CAMERA = 'vechileChangeCamera',
  GALLERY_OR_PDF = 'galleryOrPdf',
  PICTURE = 'picture',
  UPDATE_HELP_LINK = 'updateHelpLink',
  SHOW_DIALOG = 'showDialog',
  SHOW_SNACKBAR = 'showSnackbar',
}

export enum PlusActionType {
  SWITCH_DELIVERY_MODE = 'switchDeliveryMode',
  LOGOUT = 'logout',
  CHANGE_TAB = 'changeTab',
}

export enum SnackBarType {
  INFO = 0,
  WARNING = 1,
  ERROR = 2,
  SUCCESS = 3,
}
