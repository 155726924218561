import React, { useState } from 'react'
import UAParser from 'ua-parser-js'

import {
  isInAndroidDriverAppWebView,
  isInDriverAppWebView,
  isInIOSDriverAppWebView,
} from '../utils/webCall'

export const WebMeta = () => {
  const [uaParser] = useState(new UAParser())
  return (
    <div>
      <div className="vc-item">
        Browser: {JSON.stringify(uaParser.getBrowser())}
      </div>
      <div className="vc-item">
        Device: {JSON.stringify(uaParser.getDevice())}
      </div>
      <div className="vc-item">
        Engine: {JSON.stringify(uaParser.getEngine())}
      </div>
      <div className="vc-item">OS: {JSON.stringify(uaParser.getOS())}</div>
      <div className="vc-item">
        isDriverAppWebview: {String(isInDriverAppWebView())}
      </div>
      <div className="vc-item">
        isInAndroidDriverAppWebView: {String(isInAndroidDriverAppWebView())}
      </div>
      <div className="vc-item">
        isInIOSDriverAppWebView: {String(isInIOSDriverAppWebView())}
      </div>
    </div>
  )
}
