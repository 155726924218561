export enum CallbackType {
  MONITOR_SMS = 'monitorSms',
  PICK_IMAGE_FROM_ALBUM = 'pickImageFromAlbum',
  TAKE_PHOTO = 'takePhoto',
  HELP_BUTTON_CLICK_LISTENER = 'helpButtonClickListener',
  STICKER_CAMERA = 'stickerCamera',
  BACK_CONFIRM = 'backConfirm',
  OPEN_GALLERY_OR_PDF = 'openGalleryOrPdf',
  VEHICLE_CHANGE_CAMERA = 'vehilceChangeCamera',
}
