import { values } from 'ramda'
import React, { useCallback } from 'react'

import { AddressBar } from '../../components/AddressBar'
import { VCButton } from '../../components/input/VCButton'
import { SetNavBarButtonTester } from '../../components/SetNavBarButtonTester'
import { WebMeta } from '../../components/WebMeta'
import {
  StickerNavLeftButtonType,
  StickerNavRightButtonType,
} from '../../constants/navButtonType'
import { StickerSDK } from '..'

type Props = {
  sdkInstance: StickerSDK
}

export const DebugPanel = ({ sdkInstance }: Props) => {
  const handleTakePhoto = () => {
    sdkInstance.takePhoto(image => {
      console.log('Image received: ', image)
    })
  }

  const handleCloseWebview = useCallback(() => {
    sdkInstance.closeWebView()
  }, [sdkInstance])

  return (
    <div>
      <AddressBar />
      <WebMeta />
      <SetNavBarButtonTester
        sdkInstance={sdkInstance}
        availableLeftTypes={values(StickerNavLeftButtonType)}
        availableRightTypes={values(StickerNavRightButtonType)}
      />
      <VCButton type="button" onClick={handleCloseWebview}>
        Close Webview
      </VCButton>
      <VCButton type="button" onClick={handleTakePhoto}>
        Open Sticker Camera
      </VCButton>
    </div>
  )
}
