import { all, values } from 'ramda'

import { CornerType } from '../constants/cornerType'
import { SDKConfig } from '../types'
import {
  addCornerClickTracker,
  removeCornerClickTracker,
} from './windowClickTracker'

const defaultClickCounter = {
  tl: 0,
  tr: 0,
  bl: 0,
  br: 0,
}

let timeout: number | null = null
let clickCounter = { ...defaultClickCounter }

/** @internal */
const resetClickCount = () => {
  clickCounter = { ...defaultClickCounter }
}

/** @internal */
export const prodCheatCodeListener = (
  param: SDKConfig & {
    onSuccess: () => void
  }
) => {
  const {
    onSuccess,
    showDebugInfo,
    cheatCodeClickCount = 3,
    cheatCodeTimeoutSec = 3,
  } = param

  addCornerClickTracker({
    ...param,
    onCornerClick: position => {
      if (timeout) window.clearTimeout(timeout)
      timeout = window.setTimeout(() => {
        resetClickCount()
        if (showDebugInfo) console.log(clickCounter)
      }, cheatCodeTimeoutSec * 1000)

      switch (position) {
        case CornerType.TOP_LEFT:
          clickCounter.tl++
          if (clickCounter.tl > cheatCodeClickCount) {
            resetClickCount()
          }

          break
        case CornerType.TOP_RIGHT:
          clickCounter.tr++
          if (
            clickCounter.tl !== cheatCodeClickCount ||
            clickCounter.tr > cheatCodeClickCount
          ) {
            resetClickCount()
          }

          break
        case CornerType.BOTTOM_RIGHT:
          clickCounter.br++
          if (
            clickCounter.tr !== cheatCodeClickCount ||
            clickCounter.br > cheatCodeClickCount
          ) {
            resetClickCount()
          }

          break
        case CornerType.BOTTOM_LEFT:
          clickCounter.bl++
          if (
            clickCounter.br !== cheatCodeClickCount ||
            clickCounter.bl > cheatCodeClickCount
          ) {
            resetClickCount()
          }

          break
        default:
          break
      }

      if (all(v => v >= cheatCodeClickCount, values(clickCounter))) {
        onSuccess()
        resetClickCount()
        removeCornerClickTracker()
      }

      if (showDebugInfo) console.log(clickCounter)
    },
  })
}
