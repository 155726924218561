import autoBind from 'auto-bind'

import { DriverRegActionType } from '../../constants/actionType'
import { CallbackType } from '../../constants/callbackType'
import {
  DriverRegNavLeftButtonType,
  DriverRegNavRightButtonType,
} from '../../constants/navButtonType'
import { SDKConfig } from '../../types/sdkConfig'
import { shallowRemoveEmptyParam } from '../../utils/removeEmptyParam'
import { isInAndroidDriverAppWebView } from '../../utils/webCall'
import { BaseClass } from '../BaseClass'
import { DebugPanel } from './DebugPanel'

export class DriverReg extends BaseClass {
  constructor(config?: Partial<SDKConfig>) {
    super(config, DebugPanel)
    autoBind(this)
  }

  setNavigationBarButton({
    left,
    right,
  }: {
    left?: DriverRegNavLeftButtonType
    right?: DriverRegNavRightButtonType
  }) {
    return super.setNavigationBarButton({ left, right })
  }

  setShouldAutoShowRegistration(value: boolean) {
    return this.driverAppWebCall({
      action: DriverRegActionType.SET_SHOULD_AUTO_SHOW_REGISTRATION,
      value,
    })
  }

  setHelpPageLink(url: string) {
    return this.driverAppWebCall({
      action: DriverRegActionType.UPDATE_HELP_LINK,
      url,
    })
  }

  openLogin(
    params: Partial<{ password: string; phone: string; countryId: string }>
  ) {
    return this.driverAppWebCall({
      action: DriverRegActionType.OPEN_LOGIN,
      ...shallowRemoveEmptyParam(params),
    })
  }

  startMonitorSms(callback: (pin: string | null) => void) {
    const callbackFnName = CallbackType.MONITOR_SMS

    if (!isInAndroidDriverAppWebView) {
      if (this.config.showDebugInfo) {
        console.info(
          `Skipped ${callbackFnName}: This event only support android device`
        )
      }

      callback(null)
    }

    this.attachCallback({ name: callbackFnName, fn: callback })
    return this.driverAppWebCall({
      action: DriverRegActionType.START_MONITOR_SMS,
      callback: callbackFnName,
    })
  }

  pickImageFromAlbum(callback: (image: string | File) => void) {
    const callbackFnName = CallbackType.PICK_IMAGE_FROM_ALBUM
    this.attachCallback({ name: callbackFnName, fn: callback })
    return this.driverAppWebCall({
      action: DriverRegActionType.PICTURE,
      callback: callbackFnName,
    })
  }

  openGalleryOrPdf(callback: (image: string | File) => void) {
    const callbackFnName = CallbackType.OPEN_GALLERY_OR_PDF
    this.attachCallback({ name: callbackFnName, fn: callback })
    return this.driverAppWebCall({
      action: DriverRegActionType.GALLERY_OR_PDF,
      callback: callbackFnName,
    })
  }

  takePhoto(callback: (image: string | File) => void) {
    const callbackFnName = CallbackType.TAKE_PHOTO
    this.attachCallback({ name: callbackFnName, fn: callback })
    return this.driverAppWebCall({
      action: DriverRegActionType.CAMERA,
      callback: callbackFnName,
    })
  }

  registerHelpButtonClickListener(callback: () => void) {
    const callbackFnName = CallbackType.HELP_BUTTON_CLICK_LISTENER
    this.attachCallback({
      name: callbackFnName,
      fn: callback,
      destroyOnCall: false,
    })

    return this.driverAppWebCall({
      action: DriverRegActionType.CLICK_HELP_LISTENER,
      callback: callbackFnName,
    })
  }
}
