import { css } from '@emotion/css'
import React from 'react'

const inputClass = css`
  padding: 0.5em;
  background-color: transparent;
  border: 0;
  color: var(--VC-FG-0);
`

export const VCInput: React.FC<
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >
> = props => <input {...props} className={`${inputClass} ${props.className}`} />
