import { NoWebCallFnError } from '../errors/NoWebCallFnError'
import { WebCallError } from '../errors/WebCallError'
import { SDKConfig } from '../types/sdkConfig'

/** @internal */
export const nativeCall = (payload: string) => {
  // should not save the webcall to a var
  // github.com/react-native-webview/react-native-webview/issues/323#issuecomment-511824940
  if (window?.app?.webcall) {
    return window.app.webcall(payload)
  }

  if (window?.webkit?.messageHandlers?.app?.postMessage) {
    return window.webkit.messageHandlers.app.postMessage(payload)
  }

  return null
}

export const isInAndroidDriverAppWebView = () =>
  window?.app?.webcall !== undefined

export const isInIOSDriverAppWebView = () =>
  window?.webkit?.messageHandlers?.app?.postMessage !== undefined

export const isInDriverAppWebView = () =>
  isInAndroidDriverAppWebView() || isInIOSDriverAppWebView()

/** @internal */
export const driverAppWebCall = <T extends Record<string, unknown>>(
  params: T,
  { suppressError, showDebugInfo }: SDKConfig
) => {
  try {
    const jsonParams = JSON.stringify(params)
    if (showDebugInfo) console.info('web call', jsonParams)
    if (!isInDriverAppWebView()) {
      const error = new NoWebCallFnError()
      if (suppressError) return console.error(error.name, error.message)
      throw error
    }

    return nativeCall(jsonParams)
  } catch (err) {
    if (err instanceof Error) {
      const error = new WebCallError(err.message)
      if (suppressError) return console.error(error.name, error.message)
      throw error
    }

    return null
  }
}
