import React, { useCallback, useState } from 'react'

import { VCFormItem } from './VCFormItem'

type Props = {
  title: string
  onSubmit: (value: boolean) => void
  buttonText?: string
}

export const VCFormCheckbox: React.FC<Props> = ({
  title,
  onSubmit,
  buttonText,
}) => {
  const [value, setValue] = useState<boolean>(true)

  const handleSubmit = useCallback(() => {
    onSubmit(value)
  }, [value, onSubmit])

  return (
    <VCFormItem title={title} buttonText={buttonText} onSubmit={handleSubmit}>
      <input
        type="checkbox"
        checked={value}
        onChange={e => {
          setValue(e.target.checked)
        }}
      />
    </VCFormItem>
  )
}
