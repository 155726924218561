import { values } from 'ramda'
import React, { useEffect } from 'react'

import { AddressBar } from '../../../components/AddressBar'
import { VCButton } from '../../../components/input/VCButton'
import { VCFormCheckbox } from '../../../components/input/VCFormCheckbox'
import { VCFormInput } from '../../../components/input/VCFormInput'
import { SetNavBarButtonTester } from '../../../components/SetNavBarButtonTester'
import { WebMeta } from '../../../components/WebMeta'
import {
  DriverRegNavLeftButtonType,
  DriverRegNavRightButtonType,
} from '../../../constants/navButtonType'
import { DriverRegSDK } from '../../../modules'
import { OpenLoginTester } from './OpenLoginTester'

type Props = {
  sdkInstance: DriverRegSDK
}

export const DebugPanel = ({ sdkInstance }: Props) => {
  useEffect(() => {
    sdkInstance.registerHelpButtonClickListener(() => {
      console.log('Help button callback called')
    })
  }, [sdkInstance])

  const handleStartSMSMonitor = () => {
    sdkInstance.startMonitorSms(pin => {
      console.log('SMS callback received: ', pin)
    })
  }

  const handlePickImage = () => {
    sdkInstance.pickImageFromAlbum(image => {
      console.log('Image received: ', image)
    })
  }

  const handleTakePhoto = () => {
    sdkInstance.takePhoto(image => {
      console.log('Image received: ', image)
    })
  }

  return (
    <div>
      <AddressBar />
      <WebMeta />
      <SetNavBarButtonTester
        sdkInstance={sdkInstance}
        availableLeftTypes={values(DriverRegNavLeftButtonType)}
        availableRightTypes={values(DriverRegNavRightButtonType)}
      />
      <VCFormCheckbox
        title="Should auto show registration"
        onSubmit={v => {
          sdkInstance.setShouldAutoShowRegistration(v)
        }}
      />
      <VCFormInput
        title="Help page link"
        onSubmit={v => {
          if (v) sdkInstance.setHelpPageLink(v)
        }}
        placeholder="URL"
      />
      <OpenLoginTester sdkInstance={sdkInstance} />
      <VCButton type="button" onClick={handleStartSMSMonitor}>
        Start SMS monitor
      </VCButton>
      <VCButton type="button" onClick={handlePickImage}>
        Pick Image
      </VCButton>
      <VCButton type="button" onClick={handleTakePhoto}>
        Take picture
      </VCButton>
    </div>
  )
}
