import { css } from '@emotion/css'
import { isEmpty, trim } from 'ramda'
import React, { useCallback, useState } from 'react'

import { VCFormItem } from './VCFormItem'
import { VCInput } from './VCInput'

type Props = {
  title: string
  onSubmit: (value: string | undefined) => void
  placeholder?: string
  buttonText?: string
}

const inputClass = css`
  width: 100%;
  padding: 0.5em;
  background-color: transparent;
  border: 0;
  color: var(--VC-FG-0);
`

export const VCFormInput: React.FC<Props> = ({
  title,
  onSubmit,
  placeholder,
  buttonText,
}) => {
  const [value, setValue] = useState<string | undefined>('')

  const handleSubmit = useCallback(() => {
    onSubmit(isEmpty(trim(value || '')) ? undefined : value)
  }, [value, onSubmit])

  return (
    <VCFormItem title={title} buttonText={buttonText} onSubmit={handleSubmit}>
      <VCInput
        className={inputClass}
        placeholder={placeholder || 'Input'}
        value={value}
        onChange={e => {
          setValue(e.target.value)
        }}
      />
    </VCFormItem>
  )
}
