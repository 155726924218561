import { isEmpty } from 'ramda'
import React, { useCallback, useState } from 'react'

import { WebviewSDK } from '../modules'
import { VCFormItem } from './input/VCFormItem'
import { VCInput } from './input/VCInput'
import { VCSelect } from './input/VCSelect'

type Props<T extends WebviewSDK> = {
  sdkInstance: T
  availableLeftTypes: string[]
  availableRightTypes: string[]
}

export const SetNavBarButtonTester = <T extends WebviewSDK>({
  sdkInstance,
  availableLeftTypes,
  availableRightTypes,
}: Props<T>) => {
  const [leftButton, setLeftButton] = useState<string | undefined>('')
  const [rightButton, setRightButton] = useState<string | undefined>('')
  const [rightButtonText, setRightButtonText] = useState<string | undefined>('')

  const handleSubmit = useCallback(() => {
    const payload = {
      left: isEmpty(leftButton) ? undefined : leftButton,
      right: isEmpty(rightButton) ? undefined : rightButton,
      rightText: isEmpty(rightButtonText) ? undefined : rightButtonText,
    }

    sdkInstance.setNavigationBarButton(payload)
  }, [leftButton, rightButton, rightButtonText, sdkInstance])

  return (
    <VCFormItem title="Nav button" onSubmit={handleSubmit}>
      <div>
        Left:{' '}
        {!isEmpty(availableLeftTypes) ? (
          <VCSelect
            value={leftButton}
            onChange={e => {
              setLeftButton(e.target.value as string | undefined)
            }}
          >
            <option value="">-- select an option --</option>
            {availableLeftTypes.map(value => (
              <option value={value} key={value}>
                {value}
              </option>
            ))}
          </VCSelect>
        ) : (
          <VCInput
            value={leftButton}
            onChange={e => {
              setLeftButton(e.target.value as string | undefined)
            }}
            placeholder="Input"
          />
        )}
      </div>
      <div>
        Right:{' '}
        {!isEmpty(availableRightTypes) ? (
          <VCSelect
            value={rightButton}
            onChange={e => {
              setRightButton(e.target.value as string | undefined)
            }}
          >
            <option value="">-- select an option --</option>
            {availableRightTypes.map(value => (
              <option value={value} key={value}>
                {value}
              </option>
            ))}
          </VCSelect>
        ) : (
          <VCInput
            value={rightButton}
            onChange={e => {
              setRightButton(e.target.value as string | undefined)
            }}
            placeholder="Input"
          />
        )}
      </div>
      <div>
        <VCInput
          placeholder="Right Item Title"
          value={rightButtonText}
          onChange={e => {
            setRightButtonText(e.target.value)
          }}
        />
      </div>
    </VCFormItem>
  )
}
