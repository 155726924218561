import { NativeAppCallbackError } from './../errors/NativeAppCallbackError'
import { CallbackType } from '../constants/callbackType'
import { SDKConfig } from '../types/sdkConfig'

export type DetachCallbackConfig = SDKConfig & {
  name: string
}
/** @internal */
export const detachCallback = ({
  name,
  showDebugInfo,
}: DetachCallbackConfig) => {
  delete (window as any)[name]
  if (showDebugInfo) console.info(`Detached callback: ${name}`)
}

export type AttachCallbackConfig<F extends () => void> = SDKConfig & {
  name: CallbackType
  fn: F
  destroyOnCall?: boolean
  onDestroy?: () => void
}
/** @internal */
export const attachCallback = <F extends (...args: any[]) => any>({
  name,
  fn,
  destroyOnCall = true,
  onDestroy,
  ...sdkConfig
}: AttachCallbackConfig<F>) => {
  const wrappedCallback = async (
    ...args: Parameters<F>
  ): Promise<ReturnType<F> | null> => {
    try {
      if (sdkConfig.showDebugInfo) {
        console.info(`Callback ${name} called with arg: `, ...args)
      }

      return fn(...args)
    } catch (err) {
      if (err instanceof Error) {
        const error = new NativeAppCallbackError(name, err.message)
        if (sdkConfig.suppressError) {
          console.error(error.name, error.message)
          return null
        }

        throw error
      }

      return null
    } finally {
      if (destroyOnCall) {
        detachCallback({ name, ...sdkConfig })
        if (onDestroy) onDestroy()
      }
    }
  }

  Object.defineProperty(window, name, {
    value: wrappedCallback,
    enumerable: true,
    writable: true,
    configurable: true,
  })

  if (sdkConfig.showDebugInfo) console.info(`Attached callback: ${name}`)
}
