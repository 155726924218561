import autoBind from 'auto-bind'

import { StickerActionType } from '../../constants/actionType'
import { CallbackType } from '../../constants/callbackType'
import {
  StickerNavLeftButtonType,
  StickerNavRightButtonType,
} from '../../constants/navButtonType'
import { SDKConfig } from '../../types/sdkConfig'
import { shallowRemoveEmptyParam } from '../../utils/removeEmptyParam'
import { isInIOSDriverAppWebView } from '../../utils/webCall'
import { BaseClass } from '../BaseClass'
import { DebugPanel } from './DebugPanel'

export class Sticker extends BaseClass {
  constructor(config?: Partial<SDKConfig>) {
    super(config, DebugPanel)
    autoBind(this)
  }

  setNavigationBarButton({
    left,
    right,
  }: {
    left?: StickerNavLeftButtonType
    right?: StickerNavRightButtonType
  }) {
    return super.setNavigationBarButton({ left, right })
  }

  setBackButtonTracking(param?: { event: string; screen: string }) {
    return this.driverAppWebCall(
      shallowRemoveEmptyParam({
        action: StickerActionType.UPDATE_TRACKING_FOR_BACK_BUTTON,
        ...param,
      })
    )
  }

  closeWebView() {
    return this.driverAppWebCall({
      action: StickerActionType.CLOSE_WEB_VIEW,
    })
  }

  takePhoto(callback: (image: string | File) => void) {
    const callbackFnName = CallbackType.STICKER_CAMERA
    this.attachCallback({ name: callbackFnName, fn: callback })

    if (isInIOSDriverAppWebView()) {
      return this.driverAppWebCall({
        action: StickerActionType.STICKER_CAMERA,
        callback: callbackFnName,
        // Directly open camera and block pick from album
        content: JSON.stringify({ type: 1 }),
      })
    }

    // Android has problem with custom camera that viewfinder got stretch
    // Thus fallback to camera
    return this.driverAppWebCall({
      action: StickerActionType.CAMERA,
      callback: callbackFnName,
    })
  }
}
