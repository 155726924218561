import React, { useCallback, useState } from 'react'

import { VCFormItem } from '../../../components/input/VCFormItem'
import { VCInput } from '../../../components/input/VCInput'
import { DriverRegSDK } from '../../../modules'

type Props = {
  sdkInstance: DriverRegSDK
}

export const OpenLoginTester = ({ sdkInstance }: Props) => {
  const [password, setPassword] = useState<string | undefined>(undefined)
  const [phone, setPhone] = useState<string | undefined>(undefined)
  const [countryId, setCountryId] = useState<string | undefined>(undefined)

  const handleOpen = useCallback(() => {
    sdkInstance.openLogin({
      password,
      phone,
      countryId,
    })
  }, [password, phone, countryId, sdkInstance])

  return (
    <VCFormItem title="Open login" onSubmit={handleOpen} buttonText="open">
      <div>
        password:
        <VCInput
          value={password}
          onChange={e => {
            setPassword(e.target.value)
          }}
          placeholder="Input"
        />
      </div>
      <div>
        phone:
        <VCInput
          value={phone}
          onChange={e => {
            setPhone(e.target.value)
          }}
          placeholder="Input"
        />
      </div>
      <div>
        countryId:
        <VCInput
          value={countryId}
          onChange={e => {
            setCountryId(e.target.value)
          }}
          placeholder="Input"
        />
      </div>
    </VCFormItem>
  )
}
